

















































































































































































































import {
    Component,
    Vue
} from "vue-property-decorator";
import {
    Dialog,
    Toast
} from "vant";
import Mixin from "@/js/mixins";

@Component({
    mixins: [Mixin]
})
export default class HomeAgent extends Vue {
    public user_id: any = "";
    public info = {}; // 用户信息
    public isShowAll = false; //是否显示全部
    showAll() {
        this.isShowAll = !this.isShowAll
        console.log(this.isShowAll)
    }
    // 获取用户信息
    getInfo() {
        let _this = this;
        this.$api.request({
            url: "branch/user/info",
            data: {
                user_id: this.user_id
            },
            success(res) {
                _this.info = res.data
                console.log(_this.info)
            }
        });
    }

    init() {
        this.user_id = this.$route.query.id
        this.getInfo();
    }
}
